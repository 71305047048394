<template>
  <validation-observer ref="formRules" tag="form">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="6">
            <b-card title="(1) Jettyman">
              <hr />
              <b-row>
                <b-col md="4" cols="12">
                  <b-form-group label="Shift 1" label-for="vi-mobile">
                    <b-form-input
                      v-model="form.jettyman.shift1"
                      id="vi-mobile"
                      type="number"
                      placeholder="Shift 1"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" cols="12">
                  <b-form-group label="Shift 2" label-for="vi-mobile">
                    <b-form-input
                      v-model="form.jettyman.shift2"
                      id="vi-mobile"
                      type="number"
                      placeholder="Shift 2"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" cols="12">
                  <b-form-group label="Shift 3" label-for="vi-mobile">
                    <b-form-input
                      v-model="form.jettyman.shift3"
                      id="vi-mobile"
                      type="number"
                      placeholder="Shift 3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="(2) HSE">
              <hr />
              <b-row>
                <b-col cols="12">
                  <b-form-group label="HSE" label-for="vi-mobile">
                    <b-form-input
                      id="vi-mobile"
                      v-model="form.hse.value1"
                      type="number"
                      placeholder="HSE"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="6">
            <b-card title="(3) Supervisor">
              <hr />
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Shift 1" label-for="vi-mobile">
                        <b-form-input
                          id="vi-mobile"
                          v-model="form.supervisor.shift1"
                          type="number"
                          placeholder="Shift 1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Shift 2" label-for="vi-mobile">
                        <b-form-input
                          id="vi-mobile"
                          v-model="form.supervisor.shift2"
                          type="number"
                          placeholder="Shift 2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="(4) TKBM">
              <hr />
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Shift 1" label-for="vi-mobile">
                        <b-form-input
                          id="vi-mobile"
                          v-model="form.tkbm.shift1"
                          type="number"
                          placeholder="Shift 1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Shift 2" label-for="vi-mobile">
                        <b-form-input
                          id="vi-mobile"
                          v-model="form.tkbm.shift2"
                          type="number"
                          placeholder="Shift 2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-card title="(5) Pelayanan Kapal">
          <hr />
          <!-- submit and reset -->

          <!-- <b-col> {{ myDataToExport() }} </b-col> -->

          <b-col cols="12">
            <b-form-group
              :label="`PEMILIK (${
                option.lock_group_account
                  ? `you don't have permission to change`
                  : `You have permission to change`
              })`"
              label-for="vi-by_agent"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="PEMILIK"
                vid="vi-by_agent"
                rules="required"
              >
                <v-select
                  id="vi-by_agent"
                  v-model="form.by_agent"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="option.by_agents"
                  :reduce="(option) => option.value"
                  :disabled="option.lock_group_account"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  item-text="text"
                  item-value="value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Start Date ( Waktu Sandar )"
              label-for="lf_start_date"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Start Date ( Waktu Sandar )"
                vid="lf_start_date"
                rules="required"
              >
                <flat-pickr
                  id="lf_start_date"
                  v-model="form.start_date"
                  class="form-control"
                  static="true"
                  placeholder="Waktu Sandar"
                  :state="errors.length > 0 ? false : null"
                  :config="dpconfig"
                  @on-change="onStartChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="End Date ( Waktu Sandar )"
              label-for="lf_end_date"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="End Date ( Waktu Sandar )"
                vid="lf_end_date"
                rules="required"
              >
                <flat-pickr
                  id="lf_end_date"
                  v-model="form.end_date"
                  class="form-control"
                  static="true"
                  placeholder="Waktu Sandar"
                  :state="errors.length > 0 ? false : null"
                  :config="configs.end"
                  @on-change="onEndChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-row class="match-height">
          <b-col cols="6">
            <b-card title="(6) Perlengkapan Tanggap Darurat">
              <hr />
              <b-form-checkbox
                v-model="form.ptd.all"
                class="custom-control-success mb-1"
              >
                Tandai Semua
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.ert"
                class="custom-control-success mb-1"
              >
                ERT
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.oil_boom"
                class="custom-control-success mb-1"
              >
                Oil Boom
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.speed_boat"
                class="custom-control-success mb-1"
              >
                Speed Boat
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.pompa_skimmer"
                class="custom-control-success mb-1"
              >
                Pompa Skimmer
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.oil_dispersan"
                class="custom-control-success mb-1"
              >
                Oil Dispersan
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.spill_kit"
                class="custom-control-success mb-1"
              >
                Spill Kit
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.fire_hydrant"
                class="custom-control-success mb-1"
              >
                Fire Hydrant
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.apar"
                class="custom-control-success mb-1"
              >
                APAR
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.wind_shock_petunjuk_arah_angin"
                class="custom-control-success mb-1"
              >
                Wind Shock / Petunjuk Arah Angin
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.anemometer"
                class="custom-control-success mb-1"
              >
                Anemometer
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.patroli_keamanan_perairan_dengan_speedboat"
                class="custom-control-success mb-1"
              >
                Patroli Keamanan Perairan dengan Speedboat
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.ptd.patroli_keamanan_fasilitas_pelabuhan"
                class="custom-control-success mb-1"
              >
                Patroli Keamanan Fasilitas Pelabuhan
              </b-form-checkbox>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="(7) Peralatan Kerja">
              <hr />
              <b-form-checkbox
                v-model="form.pk.all"
                class="custom-control-success mb-1"
              >
                Tandai Semua
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_forklift"
                class="custom-control-success mb-1"
              >
                Sertifikat Forklift
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_crane"
                class="custom-control-success mb-1"
              >
                Sertifikat Crane
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_extension_fork"
                class="custom-control-success mb-1"
              >
                Sertifikat Extension Fork
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_wire_rope_sling"
                class="custom-control-success mb-1"
              >
                Sertifikat Wire Rope Sling
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_sackle"
                class="custom-control-success mb-1"
              >
                Sertifikat Sackle
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_eye_hook"
                class="custom-control-success mb-1"
              >
                Sertifikat Eye Hook
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_webing_sling"
                class="custom-control-success mb-1"
              >
                Sertifikat Webing Sling
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_pallet_lifter"
                class="custom-control-success mb-1"
              >
                Sertifikat Pallet Lifter
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pk.sertifikat_spider_bar"
                class="custom-control-success mb-1"
              >
                Sertifikat Spider Bar
              </b-form-checkbox>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-row class="match-height">
          <b-col cols="6">
            <b-card title="(8) Kualifikasi & Kompetensi Tenaga Kerja">
              <hr />
              <b-form-checkbox
                v-model="form.kktk.all"
                class="custom-control-success mb-1"
              >
                Tandai Semua
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_rigger"
                class="custom-control-success mb-1"
              >
                Sertifikat Rigger
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_operator_forklift"
                class="custom-control-success mb-1"
              >
                Sertifikat Operator Forklift
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_operator_crane"
                class="custom-control-success mb-1"
              >
                Sertifikat Operator Crane
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_garda_pratama"
                class="custom-control-success mb-1"
              >
                Sertifikat Garda Pratama
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_ahli_k_3_umum"
                class="custom-control-success mb-1"
              >
                Sertifikat Ahli K3 Umum
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_ahli_k_3_kebakaran"
                class="custom-control-success mb-1"
              >
                Sertifikat Ahli K3 Kebakaran
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.kktk.sertifikat_ahli_k_3_kimia"
                class="custom-control-success mb-1"
              >
                Sertifikat Ahli K3 Kimia
              </b-form-checkbox>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card title="(9) Pemantauan Lingkungan">
              <hr />
              <b-form-checkbox
                v-model="form.pl.all"
                class="custom-control-success mb-1"
              >
                Tandai Semua
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pl.pemantauan_air_limbah"
                class="custom-control-success mb-1"
              >
                Pemantauan Air Limbah
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.pl.pemantauan_badan_air_permukaan"
                class="custom-control-success mb-1"
              >
                Pemantauan Badan Air Permukaan
              </b-form-checkbox>
              <b-form-checkbox
                v-model="
                  form.pl.pemantauan_kualitas_udara_ambien_emisi_dan_debu
                "
                class="custom-control-success mb-1"
              >
                Pemantauan Kualitas Udara Ambien, emisi dan debu
              </b-form-checkbox>
            </b-card>
          </b-col>

          <b-col cols="12">
            <br />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="generateExcel()"
              variant="primary"
              class="mb-1"
            >
              <feather-icon icon="CheckCircleIcon" class="mr-25" />
              Generate Excel File
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BCardText
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import { v4 as uuidv4, NIL as uuidnil } from 'uuid'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { getUserData } from '@/auth/utils'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    BCard,
    BCardFooter,
    BCardText,
    VSelect,
    FeatherIcon
  },
  directives: {
    Ripple
  },
  computed: {},
  watch: {
    'form.ptd.all': function (value) {
      this.form.ptd.ert = value
      this.form.ptd.oil_boom = value
      this.form.ptd.speed_boat = value
      this.form.ptd.pompa_skimmer = value
      this.form.ptd.oil_dispersan = value
      this.form.ptd.spill_kit = value
      this.form.ptd.fire_hydrant = value
      this.form.ptd.apar = value
      this.form.ptd.wind_shock_petunjuk_arah_angin = value
      this.form.ptd.anemometer = value
      this.form.ptd.patroli_keamanan_perairan_dengan_speedboat = value
      this.form.ptd.patroli_keamanan_fasilitas_pelabuhan = value
    },
    'form.pk.all': function (value) {
      this.form.pk.sertifikat_forklift = value
      this.form.pk.sertifikat_crane = value
      this.form.pk.sertifikat_extension_fork = value
      this.form.pk.sertifikat_wire_rope_sling = value
      this.form.pk.sertifikat_sackle = value
      this.form.pk.sertifikat_eye_hook = value
      this.form.pk.sertifikat_webing_sling = value
      this.form.pk.sertifikat_pallet_lifter = value
      this.form.pk.sertifikat_spider_bar = value
    },
    'form.kktk.all': function (value) {
      this.form.kktk.sertifikat_rigger = value
      this.form.kktk.sertifikat_operator_forklift = value
      this.form.kktk.sertifikat_operator_crane = value
      this.form.kktk.sertifikat_garda_pratama = value
      this.form.kktk.sertifikat_ahli_k_3_umum = value
      this.form.kktk.sertifikat_ahli_k_3_kebakaran = value
      this.form.kktk.sertifikat_ahli_k_3_kimia = value
    },
    'form.pl.all': function (value) {
      this.form.pl.pemantauan_air_limbah = value
      this.form.pl.pemantauan_badan_air_permukaan = value
      this.form.pl.pemantauan_kualitas_udara_ambien_emisi_dan_debu = value
    },

    'form.by_agent': function () {
      const agent = this.form.by_agent
      if (agent === 'SLB') {
        this.flag_agent = false
        // this.form.capacity_truck = null
      } else {
        this.form.jenis_truck = null
        this.flag_agent = true
      }
      this.API_SAL = this.MAP_API[agent]
    }
  },
  data() {
    return {
      component_vi_segmen: 1,
      dpconfig: {
        // default
        wrap: true,
        enableTime: false,
        time_24hr: false,
        // altInput: true,
        // dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'j F Y',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: false,
        time_24hr: false,
        altInput: true,
        altFormat: 'j F Y',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          enableTime: false,
          time_24hr: false,
          altInput: true,
          altFormat: 'j F Y',
          dateFormat: 'Y-m-d',
          locale: Indonesian,
          minDate: null
        }
      },
      flag_agent: true,
      form: {
        by_agent: null,
        start_date: null,
        end_date: null,
        // capacity_truck: 'ALL',
        jenis_truck: 'ALL',
        segmen: null,
        jettyman: {
          shift1: null,
          shift2: null,
          shift3: null
        },
        hse: {
          value1: null
        },
        supervisor: {
          shift1: null,
          shift2: null
        },
        tkbm: {
          shift1: null,
          shift2: null
        },
        ptd: {
          all: false,
          ert: false,
          oil_boom: false,
          speed_boat: false,
          pompa_skimmer: false,
          oil_dispersan: false,
          spill_kit: false,
          fire_hydrant: false,
          apar: false,
          wind_shock_petunjuk_arah_angin: false,
          anemometer: false,
          patroli_keamanan_perairan_dengan_speedboat: false,
          patroli_keamanan_fasilitas_pelabuhan: false
        },
        pk: {
          all: false,
          sertifikat_forklift: false,
          sertifikat_crane: false,
          sertifikat_extension_fork: false,
          sertifikat_wire_rope_sling: false,
          sertifikat_sackle: false,
          sertifikat_eye_hook: false,
          sertifikat_webing_sling: false,
          sertifikat_pallet_lifter: false,
          sertifikat_spider_bar: false
        },
        kktk: {
          all: false,
          sertifikat_rigger: false,
          sertifikat_operator_forklift: false,
          sertifikat_operator_crane: false,
          sertifikat_garda_pratama: false,
          sertifikat_ahli_k_3_umum: false,
          sertifikat_ahli_k_3_kebakaran: false,
          sertifikat_ahli_k_3_kimia: false
        },
        pl: {
          all: false,
          pemantauan_air_limbah: false,
          pemantauan_badan_air_permukaan: false,
          pemantauan_kualitas_udara_ambien_emisi_dan_debu: false
        }
      },
      MAP_API: {
        TEM: 'vw_monitor_jetty/reporting-supervisi',
        PATRA: 'vw_monitor_jetty/reporting-supervisi',
        PIL: 'vw_monitor_jetty/reporting-supervisi',
        SLB: 'vw_monitor_jetty/reporting-supervisi',
        'Hulu Migas': 'vw_monitor_jetty/reporting-supervisi',
        ALL: 'vw_monitor_jetty/reporting-supervisi',
        TBBM: 'vw_monitor_jetty/reporting-supervisi',
        'JETTY 3A': 'vw_monitor_jetty/reporting-supervisi'
      },
      API_SAL: 'vw_monitor_jetty/reporting-supervisi',
      BACKEND_URI_SAL: process.env.VUE_APP_BACKEND_URL,

      option: {
        lock_group_account: true,
        by_agents: [
          {
            text: 'JETTY 3A',
            value: 'JETTY 3A'
          },
          {
            text: 'TBBM (TEM,PATRA, dan PIL)',
            value: 'TBBM'
          },
          { text: 'TEM', value: 'TEM' },
          { text: 'PATRA', value: 'PATRA' },
          { text: 'PIL', value: 'PIL' },
          { text: 'Hulu Migas', value: 'Hulu Migas' },
          { text: 'ALL', value: 'ALL' }
        ],
        // capacity_trucks: [
        //   { text: 'Kapasitas Truck', value: 'null' },
        //   { text: '5.000 Liter', value: '5000' },
        //   { text: '10.000 Liter', value: '10000' },
        //   { text: '16.000 Liter', value: '16000' },
        //   { text: '20.000 Liter', value: '20000' },
        //   { text: 'ALL', value: 'ALL' }
        // ],
        jenis_trucks: [
          { text: 'Jenis Truck', value: 'null' },
          { text: 'TRUCK', value: 'TRUCK' },
          { text: 'LONGBED', value: 'LONGBED' },
          { text: 'TRAILER', value: 'TRAILER' },
          { text: 'MOBIL BOX', value: 'MOBIL BOX' },
          { text: 'MOBIL', value: 'MOBIL' },
          { text: 'BIS', value: 'BIS' },
          { text: 'ALL', value: 'ALL' }
        ],
        segmens: [
          { text: 'ELNUSA LMP', value: 'ELNUSA LMP' },
          { text: 'MI (LMP)', value: 'MI (LMP)' },
          { text: 'DAS (Cementing)', value: 'DAS (Cementing)' },
          // { text: 'Facility (SLB)', value: 'Facility (SLB)' },
          { text: 'RPI', value: 'RPI' },
          { text: 'ELNUSA (Cementing)', value: 'ELNUSA (Cementing)' },
          { text: 'SAL', value: 'SAL' }
        ]
      }
    }
  },
  created() {
    const userData = getUserData()
    const group = userData.group?.name ?? null
    if (this.$can('manage', 'all')) {
      this.option.lock_group_account = false
    } else {
      if (!!group) {
        /* slice data and disable group account change */
        this.option.lock_group_account = true
        // this.form.by_agent = `${group}`
        if (this.$can('SAL', '')) {
          this.option.lock_group_account = false
        }
        if (this.$can('TBBM JETTY 3A', '')) {
          this.form.by_agent = 'JETTY 3A'
        }
      } else {
        this.option.lock_group_account = true
        this.form.by_agent = 'TBBM'
      }
    }

    // segmen change
    if (!this.$can('manage', 'all')) {
      const segmens = []
      this.$formSegmenSlb.forEach((value) => {
        if (this.$can(value.key, '')) {
          segmens.push({
            text: value.key,
            value: value.key
          }) /* elnusa cementing membawahi elnusa lmp (can create and approve) */
          if (value.key === 'ELNUSA (Cementing)') {
            segmens.push({ text: 'ELNUSA LMP', value: 'ELNUSA LMP' })
          }
        }
      })
      this.form.segmen = segmens[0].value
      this.option.segmens = segmens
    }
  },
  methods: {
    myDataToExport() {
      let label = null
      if (this.$can('SAL', '')) {
        return (label = null)
      }
      if (this.$can('TBBM', '')) {
        return (label = 'TBBM')
      }
      if (this.$can('Hulu Migas', '')) {
        return (label = 'Hulu Migas')
      }
      if (this.$can('approvement', '')) {
        return (label = 'TBBM')
      }
      if (this.$can('manage', 'special')) {
        return (label = 'TBBM')
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      // 2022-08-22T13:34:00.000Z
      this.dpconfig.maxDate = dateStr
    },
    selectAllSegmen(flag) {
      if (flag === true) {
        /* selectAll */
        const segmens = []
        this.option.segmens.forEach((element) => {
          segmens.push(element.value)
        })
        this.form.segmen = segmens
      } else {
        /* deSelectAll */
        this.form.segmen = null
      }
    },
    resetForm() {
      // this.form.by_agent = null
      // this.form.capacity_truck = null
      this.form.jenis_truck = null
      this.form.start_date = null
      this.form.clock_out = null
    },
    generateExcel() {
      const payload = this.form
      console.log(payload)
      // this.$swal({
      //   title: 'Coming Soon!',
      //   text: '-',
      //   icon: 'info',
      //   timer: 1500,
      //   showCancelButton: false,
      //   customClass: {
      //     confirmButton: 'btn btn-success'
      //   },
      //   buttonsStyling: false
      // })

      return new Promise((resolve, reject) => {
        this.$refs.formRules.validate().then((success) => {
          if (success) {
            this.requestGet()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    requestGet() {
      useJwt.http
        .get(this.API_SAL, { params: this.form })
        .then((response) => {
          const uri = `${this.BACKEND_URI_SAL}/${response.data.link_file_excel}`
          console.log(uri)
          window.open(uri)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style></style>
