<template>
  <b-row>
    <b-col cols="12">
      <h1>SUPERVISI DAN MONITORING</h1>
      <hr />
    </b-col>
    <b-col>
      <KegiatanOperasional />
    </b-col>
    <!-- img -->
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardFooter, BImg, BCardText } from 'bootstrap-vue'
import KegiatanOperasional from './KegiatanOperasional.vue'

import store from '@/store/index'
export default {
  components: {
    KegiatanOperasional,
    BCard,
    BCardFooter,
    BCardText,
    BRow,
    BImg,
    BCol
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/register-v2.svg')
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.downImg
      }
      return this.downImg
    }
  }
}
</script>

<style></style>
