var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "formRules",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(1) Jettyman"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 1",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 1"
    },
    model: {
      value: _vm.form.jettyman.shift1,
      callback: function callback($$v) {
        _vm.$set(_vm.form.jettyman, "shift1", $$v);
      },
      expression: "form.jettyman.shift1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 2",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 2"
    },
    model: {
      value: _vm.form.jettyman.shift2,
      callback: function callback($$v) {
        _vm.$set(_vm.form.jettyman, "shift2", $$v);
      },
      expression: "form.jettyman.shift2"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 3",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 3"
    },
    model: {
      value: _vm.form.jettyman.shift3,
      callback: function callback($$v) {
        _vm.$set(_vm.form.jettyman, "shift3", $$v);
      },
      expression: "form.jettyman.shift3"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(2) HSE"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "HSE",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "HSE"
    },
    model: {
      value: _vm.form.hse.value1,
      callback: function callback($$v) {
        _vm.$set(_vm.form.hse, "value1", $$v);
      },
      expression: "form.hse.value1"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(3) Supervisor"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 1",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 1"
    },
    model: {
      value: _vm.form.supervisor.shift1,
      callback: function callback($$v) {
        _vm.$set(_vm.form.supervisor, "shift1", $$v);
      },
      expression: "form.supervisor.shift1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 2",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 2"
    },
    model: {
      value: _vm.form.supervisor.shift2,
      callback: function callback($$v) {
        _vm.$set(_vm.form.supervisor, "shift2", $$v);
      },
      expression: "form.supervisor.shift2"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(4) TKBM"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 1",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 1"
    },
    model: {
      value: _vm.form.tkbm.shift1,
      callback: function callback($$v) {
        _vm.$set(_vm.form.tkbm, "shift1", $$v);
      },
      expression: "form.tkbm.shift1"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Shift 2",
      "label-for": "vi-mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-mobile",
      "type": "number",
      "placeholder": "Shift 2"
    },
    model: {
      value: _vm.form.tkbm.shift2,
      callback: function callback($$v) {
        _vm.$set(_vm.form.tkbm, "shift2", $$v);
      },
      expression: "form.tkbm.shift2"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(5) Pelayanan Kapal"
    }
  }, [_c('hr'), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "PEMILIK (".concat(_vm.option.lock_group_account ? "you don't have permission to change" : "You have permission to change", ")"),
      "label-for": "vi-by_agent",
      "label-cols-md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "PEMILIK",
      "vid": "vi-by_agent",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-by_agent",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.option.by_agents,
            "reduce": function reduce(option) {
              return option.value;
            },
            "disabled": _vm.option.lock_group_account,
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "label": "text",
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.form.by_agent,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "by_agent", $$v);
            },
            expression: "form.by_agent"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Start Date ( Waktu Sandar )",
      "label-for": "lf_start_date",
      "label-cols-md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Start Date ( Waktu Sandar )",
      "vid": "lf_start_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "lf_start_date",
            "static": "true",
            "placeholder": "Waktu Sandar",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.start_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "start_date", $$v);
            },
            expression: "form.start_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "End Date ( Waktu Sandar )",
      "label-for": "lf_end_date",
      "label-cols-md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "End Date ( Waktu Sandar )",
      "vid": "lf_end_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "lf_end_date",
            "static": "true",
            "placeholder": "Waktu Sandar",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.end_date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "end_date", $$v);
            },
            expression: "form.end_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(6) Perlengkapan Tanggap Darurat"
    }
  }, [_c('hr'), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.all,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "all", $$v);
      },
      expression: "form.ptd.all"
    }
  }, [_vm._v(" Tandai Semua ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.ert,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "ert", $$v);
      },
      expression: "form.ptd.ert"
    }
  }, [_vm._v(" ERT ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.oil_boom,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "oil_boom", $$v);
      },
      expression: "form.ptd.oil_boom"
    }
  }, [_vm._v(" Oil Boom ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.speed_boat,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "speed_boat", $$v);
      },
      expression: "form.ptd.speed_boat"
    }
  }, [_vm._v(" Speed Boat ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.pompa_skimmer,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "pompa_skimmer", $$v);
      },
      expression: "form.ptd.pompa_skimmer"
    }
  }, [_vm._v(" Pompa Skimmer ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.oil_dispersan,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "oil_dispersan", $$v);
      },
      expression: "form.ptd.oil_dispersan"
    }
  }, [_vm._v(" Oil Dispersan ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.spill_kit,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "spill_kit", $$v);
      },
      expression: "form.ptd.spill_kit"
    }
  }, [_vm._v(" Spill Kit ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.fire_hydrant,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "fire_hydrant", $$v);
      },
      expression: "form.ptd.fire_hydrant"
    }
  }, [_vm._v(" Fire Hydrant ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.apar,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "apar", $$v);
      },
      expression: "form.ptd.apar"
    }
  }, [_vm._v(" APAR ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.wind_shock_petunjuk_arah_angin,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "wind_shock_petunjuk_arah_angin", $$v);
      },
      expression: "form.ptd.wind_shock_petunjuk_arah_angin"
    }
  }, [_vm._v(" Wind Shock / Petunjuk Arah Angin ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.anemometer,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "anemometer", $$v);
      },
      expression: "form.ptd.anemometer"
    }
  }, [_vm._v(" Anemometer ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.patroli_keamanan_perairan_dengan_speedboat,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "patroli_keamanan_perairan_dengan_speedboat", $$v);
      },
      expression: "form.ptd.patroli_keamanan_perairan_dengan_speedboat"
    }
  }, [_vm._v(" Patroli Keamanan Perairan dengan Speedboat ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.ptd.patroli_keamanan_fasilitas_pelabuhan,
      callback: function callback($$v) {
        _vm.$set(_vm.form.ptd, "patroli_keamanan_fasilitas_pelabuhan", $$v);
      },
      expression: "form.ptd.patroli_keamanan_fasilitas_pelabuhan"
    }
  }, [_vm._v(" Patroli Keamanan Fasilitas Pelabuhan ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(7) Peralatan Kerja"
    }
  }, [_c('hr'), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.all,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "all", $$v);
      },
      expression: "form.pk.all"
    }
  }, [_vm._v(" Tandai Semua ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_forklift,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_forklift", $$v);
      },
      expression: "form.pk.sertifikat_forklift"
    }
  }, [_vm._v(" Sertifikat Forklift ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_crane,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_crane", $$v);
      },
      expression: "form.pk.sertifikat_crane"
    }
  }, [_vm._v(" Sertifikat Crane ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_extension_fork,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_extension_fork", $$v);
      },
      expression: "form.pk.sertifikat_extension_fork"
    }
  }, [_vm._v(" Sertifikat Extension Fork ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_wire_rope_sling,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_wire_rope_sling", $$v);
      },
      expression: "form.pk.sertifikat_wire_rope_sling"
    }
  }, [_vm._v(" Sertifikat Wire Rope Sling ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_sackle,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_sackle", $$v);
      },
      expression: "form.pk.sertifikat_sackle"
    }
  }, [_vm._v(" Sertifikat Sackle ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_eye_hook,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_eye_hook", $$v);
      },
      expression: "form.pk.sertifikat_eye_hook"
    }
  }, [_vm._v(" Sertifikat Eye Hook ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_webing_sling,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_webing_sling", $$v);
      },
      expression: "form.pk.sertifikat_webing_sling"
    }
  }, [_vm._v(" Sertifikat Webing Sling ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_pallet_lifter,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_pallet_lifter", $$v);
      },
      expression: "form.pk.sertifikat_pallet_lifter"
    }
  }, [_vm._v(" Sertifikat Pallet Lifter ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pk.sertifikat_spider_bar,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pk, "sertifikat_spider_bar", $$v);
      },
      expression: "form.pk.sertifikat_spider_bar"
    }
  }, [_vm._v(" Sertifikat Spider Bar ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(8) Kualifikasi & Kompetensi Tenaga Kerja"
    }
  }, [_c('hr'), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.all,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "all", $$v);
      },
      expression: "form.kktk.all"
    }
  }, [_vm._v(" Tandai Semua ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_rigger,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_rigger", $$v);
      },
      expression: "form.kktk.sertifikat_rigger"
    }
  }, [_vm._v(" Sertifikat Rigger ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_operator_forklift,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_operator_forklift", $$v);
      },
      expression: "form.kktk.sertifikat_operator_forklift"
    }
  }, [_vm._v(" Sertifikat Operator Forklift ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_operator_crane,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_operator_crane", $$v);
      },
      expression: "form.kktk.sertifikat_operator_crane"
    }
  }, [_vm._v(" Sertifikat Operator Crane ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_garda_pratama,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_garda_pratama", $$v);
      },
      expression: "form.kktk.sertifikat_garda_pratama"
    }
  }, [_vm._v(" Sertifikat Garda Pratama ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_ahli_k_3_umum,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_ahli_k_3_umum", $$v);
      },
      expression: "form.kktk.sertifikat_ahli_k_3_umum"
    }
  }, [_vm._v(" Sertifikat Ahli K3 Umum ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_ahli_k_3_kebakaran,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_ahli_k_3_kebakaran", $$v);
      },
      expression: "form.kktk.sertifikat_ahli_k_3_kebakaran"
    }
  }, [_vm._v(" Sertifikat Ahli K3 Kebakaran ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.kktk.sertifikat_ahli_k_3_kimia,
      callback: function callback($$v) {
        _vm.$set(_vm.form.kktk, "sertifikat_ahli_k_3_kimia", $$v);
      },
      expression: "form.kktk.sertifikat_ahli_k_3_kimia"
    }
  }, [_vm._v(" Sertifikat Ahli K3 Kimia ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "(9) Pemantauan Lingkungan"
    }
  }, [_c('hr'), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pl.all,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pl, "all", $$v);
      },
      expression: "form.pl.all"
    }
  }, [_vm._v(" Tandai Semua ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pl.pemantauan_air_limbah,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pl, "pemantauan_air_limbah", $$v);
      },
      expression: "form.pl.pemantauan_air_limbah"
    }
  }, [_vm._v(" Pemantauan Air Limbah ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pl.pemantauan_badan_air_permukaan,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pl, "pemantauan_badan_air_permukaan", $$v);
      },
      expression: "form.pl.pemantauan_badan_air_permukaan"
    }
  }, [_vm._v(" Pemantauan Badan Air Permukaan ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success mb-1",
    model: {
      value: _vm.form.pl.pemantauan_kualitas_udara_ambien_emisi_dan_debu,
      callback: function callback($$v) {
        _vm.$set(_vm.form.pl, "pemantauan_kualitas_udara_ambien_emisi_dan_debu", $$v);
      },
      expression: "\n                form.pl.pemantauan_kualitas_udara_ambien_emisi_dan_debu\n              "
    }
  }, [_vm._v(" Pemantauan Kualitas Udara Ambien, emisi dan debu ")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('br'), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.generateExcel();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }), _vm._v(" Generate Excel File ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }